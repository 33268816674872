import React from 'react'

import sprites from '../../media/sprites.jsx'
import { v1 as uuid } from 'uuid'

const SvgSlider = () => {
  const allArrayItems = [
    null,
    ...Object.keys(sprites).map((element) => {
      return sprites[element]
    }),
  ]

  const generator = (min, max, color1, color2) => {
    let output = []
    for (let i = min; i <= max; i++) {
      output.push(
        <div className="svg-slider__item" key={i}>
          {allArrayItems[i](color1, color2, uuid())}
        </div>
      )
    }
    return output
  }

  return (
    <div className="svg-slider_primary">
      <div className="svg-slider__container">
        <div className="svg-slider_items__container svg-slider_items__container-1">
          {generator(1, 9, 'red', 'blue')}
          {generator(1, 9, 'red', 'blue')}
          {generator(1, 9, 'red', 'blue')}
          {generator(1, 9, 'red', 'blue')}
          {generator(1, 9, 'red', 'blue')}
          {generator(1, 9, 'red', 'blue')}
        </div>
      </div>
      <div className="svg-slider__container">
        <div className="svg-slider__container_negative_wrapper">
          <div className="svg-slider_items__container svg-slider_items__container-2">
            {generator(10, 18, 'blue', 'green')}
            {generator(10, 18, 'blue', 'green')}
            {generator(10, 18, 'blue', 'green')}
            {generator(10, 18, 'blue', 'green')}
            {generator(10, 18, 'blue', 'green')}
            {generator(10, 18, 'blue', 'green')}
            {/* {generator(10, 13, 'blue', 'green')} */}
          </div>
        </div>
      </div>
      <div className="svg-slider__container">
        <div className="svg-slider_items__container svg-slider_items__container-3">
          {generator(19, 28, 'green', 'red')}
          {generator(19, 28, 'green', 'red')}
          {generator(19, 28, 'green', 'red')}
          {generator(19, 28, 'green', 'red')}
          {generator(19, 28, 'green', 'red')}
          {generator(19, 28, 'green', 'red')}
          {/* {generator(19, 21, 'green', 'red')} */}
        </div>
      </div>
    </div>
  )
}

export default SvgSlider
