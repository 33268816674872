import React from 'react'

const Display1 = (props) => {
  const { knowMoreRef } = props
  return (
    <div className="display display1">
      <div className="display_container">
        <h4 className="display_title" ref={knowMoreRef}>
          &nbsp;My Skills&nbsp;
        </h4>
      </div>
    </div>
  )
}

export default Display1
