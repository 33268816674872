import React from 'react'

const contactiteminput = (props) => {
  const { labelText, dataType, inputValue, inputName, handleOnChange, formDisable } = props

  return (
    <div className="contact_container">
      <div className="contact_group contact_fixed_size">
        <input
          className="contact_group_input"
          type={dataType}
          id={inputName}
          placeholder=" "
          required
          onChange={handleOnChange}
          value={inputValue}
          name={inputName}
          disabled={formDisable}
        />
        <label className="contact_group_label" htmlFor={inputName}>
          {labelText + ':'}
        </label>
        <div className="contact_group_line_container"></div>
      </div>
    </div>
  )
}

export default contactiteminput
