import React from 'react'

const Display3 = () => {
  return (
    <div className="display display3">
      <div className="display_container">
        <h4 className="display_title">&nbsp;Contact&nbsp;</h4>
      </div>
    </div>
  )
}

export default Display3
