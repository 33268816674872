import React, { useState, useEffect } from 'react'
import vanilla from '../../media/vanilla'
import helper from '../../helpers/bounding'

const Portfolioitem = (props) => {
  const { data, unique } = props
  const {
    portfolio_description_title,
    portfolio_description_paragraph1,
    portfolio_description_paragraph2,
    portfolio_description_recap,
    portfolio_description_recap2,
    imageShowcase,
    websiteURL,
  } = data

  const [solar, setSolar] = useState('')

  useEffect(() => {
    window.addEventListener('scroll', scrollEventer)
    // eslint-disable-next-line
  }, [])

  let scrollEventer = (event) => {
    const target = document.querySelector(`#pain${unique}`)
    if (target) {
      const checker = helper.isInViewport(target)
      if (checker) {
        window.removeEventListener('scroll', scrollEventer)
        setSolar('solbox')
      }
    }
  }

  return (
    <div className="portfolio_container">
      <div className="portfolio_wrapper">
        <div className="portfolio_item" id={`pain${unique}`}>
          <div className={`portfolio_description ${solar}`}>
            <h4 className="portfolio_description_title">{portfolio_description_title}</h4>
            <p className="portfolio_description_paragraph">{portfolio_description_paragraph1}</p>
            <p className="portfolio_description_paragraph">{portfolio_description_paragraph2}</p>
            <p className="portfolio_description_recap">{portfolio_description_recap}</p>
            <p className="portfolio_description_recap">{portfolio_description_recap2}</p>
            <div className="portfolio_description_eye_wrapper">
              <a className="portfolio_description_eye_container" href={websiteURL} target="_blank" rel="noopener noreferrer">
                <span className="portfolio_description_eye">{vanilla.a1Eye()}</span>
              </a>

              {/* <div className="portfolio_description_eye_container"></div> */}
            </div>
          </div>
          <div className={`portfolio_image_container ${solar}`}>
            <a href={websiteURL} target="_blank" rel="noopener noreferrer">
              <img className="portfolio_image" src={imageShowcase} alt="iland" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Portfolioitem
