import React from 'react'

const mouse = () => {
  return (
    <div className="mouse mt-s">
      <div className="mouse_dot"></div>
    </div>
  )
}

export default mouse
