import React, { Fragment } from 'react'

import DDrive from '../../media/images/DDrive.png'
import LoanLogik from '../../media/images/LoanLogik.png'
import Milo2 from '../../media/images/milo2.png'
import meublessridurga from '../../media/images/meublessridurga.png'
import mollie from '../../media/images/mollie.png'
import league from '../../media/images/league.png'
import fscogroup from '../../media/images/fscogroup.png'
import food from '../../media/images/food.png'
import Portfolioitem from './portfolioitem'

const portfolio = () => {
  const information = [
    {
      portfolio_description_title: 'Meubles Sri Durga 2020 July',
      portfolio_description_paragraph1: 'Furniture Shop E-Commerce',
      portfolio_description_paragraph2: 'Languages used: Javascript, React, Redux, HTML, CSS, SASS, Node.js, MongoDB, DigitalOcean',
      portfolio_description_recap: 'In Progress',
      imageShowcase: meublessridurga,
      websiteURL: 'https://meublessridurga.nymsolarexe.com/',
    },

    {
      portfolio_description_title: 'Mollie Clone 2020 June',
      portfolio_description_paragraph2: 'Languages used: Javascript, HTML, CSS, SASS, DigitalOcean',
      portfolio_description_recap: 'In Progress',
      portfolio_description_recap2: `I really like the Mollie website so its a fan made recreation`,
      imageShowcase: mollie,
      websiteURL: 'https://mollie.nymsolarexe.com/',
    },

    {
      portfolio_description_title: 'FSCO Group 2020 June',
      portfolio_description_paragraph1: 'FSCO Group Landing Page',
      portfolio_description_paragraph2: 'Languages used: Javascript, HTML, CSS, SASS, Bootstrap, PhP, Laravel, Cloudways',
      portfolio_description_recap2: `The backend was originally created in Node.js /w DigitalOcean but Cloudways hosting does not support Node.js so I rewrote it in PhP based Laravel.`,
      imageShowcase: fscogroup,
      websiteURL: 'https://fscogroup.com/',
    },

    {
      portfolio_description_title: 'Loan Logik 2020 May',
      portfolio_description_paragraph1: 'Loan Management System',
      portfolio_description_paragraph2: 'Languages used: Javascript, jQuery, HTML, CSS, Bootstrap, PhP, Laravel, MySql, Sendgrid, Cloudways.',
      portfolio_description_recap:
        'Customers can apply for a loan (4 Stages). If approved, the admin will generate a pdf contract for them that must be signed and uploaded',
      portfolio_description_recap2:
        'Created the full stack application alone. Converted HTML CSS Laravel.Blade into a simple interest or compound interest PDF contract. Rendered sensitive client uploaded documents from the server to the browser via XHR so it cannot be accessed publicly.',
      imageShowcase: LoanLogik,
      websiteURL: 'https://phpstack-171372-1227426.cloudwaysapps.com/apply',
    },

    {
      portfolio_description_title: 'Grocery Shopping 2020 April',
      portfolio_description_paragraph1: 'Grocery Shopping list',
      portfolio_description_paragraph2: 'Languages used: Javascript, jQuery, HTML, CSS, Bootstrap, PhP, Laravel, MySql, DigitalOcean.',
      portfolio_description_recap: 'Created for my wife so we can manage grocery shopping together',
      imageShowcase: food,
      websiteURL: 'https://food.nymsolarexe.com/login',
    },

    {
      portfolio_description_title: 'Milo Pets 2020 March',
      portfolio_description_paragraph1: 'Pet Management System for Municipality',
      portfolio_description_paragraph2:
        'Languages used: Javascript, jQuery, HTML, CSS, Bootstrap, PhP, Laravel, MySql, Stripe, Jira, Sendgrid, HighChart.JS, Cloudways.',
      portfolio_description_recap:
        'Citizens can signup and register their pets. Employees can perform CRUD operations. Admins can monitor revenue generated.',
      portfolio_description_recap2:
        'Ported project from laravel 4 to laravel 6, removed dead code and fixed many bugs. Created a random SQL data generator to simulate high traffic. Created a jira database to track bugs. Turned minimum viable product to full stack application in 2 months',
      imageShowcase: Milo2,
      websiteURL: 'https://spcaouest.milotag.com/en/login',
    },

    {
      portfolio_description_title: 'League Calculator 2020 February',
      portfolio_description_paragraph1: 'League of Legends Stats',
      portfolio_description_paragraph2: 'Languages used: Javascript, HTML, CSS, Bootstrap, Node.js, Riot Games API, DigitalOcean.',
      portfolio_description_recap:
        'Players can look up a champions stats for level 1 to 18. Random splashart image is selected from D-Dragon League API',
      imageShowcase: league,
      websiteURL: 'https://league.nymsolarexe.com/',
    },

    {
      portfolio_description_title: 'D-Drive 2019 July',
      portfolio_description_paragraph1: 'Car Rental Management System',
      portfolio_description_paragraph2: 'Languages used: Javascript, jQuery, HTML, CSS, Bootstrap, PhP, MySql, CPanel.',
      portfolio_description_recap: 'Customers can reserve, rent and return cars. Employees must approve and verify all stages of the rental',
      portfolio_description_recap2: 'School Project using primarily PhP MVC OOP. Netflix like landing page.',
      imageShowcase: DDrive,
      websiteURL: 'https://5206210.herzingmontreal.ca/MVC/',
    },
  ]

  return (
    <Fragment>
      <div className="portfolio">
        {information.map((element, index) => (
          <Portfolioitem data={element} key={index} unique={index} />
        ))}
      </div>
    </Fragment>
  )
}

export default portfolio
