import React, { useState } from 'react'
import vanilla from '../../media/vanilla'

const Modal = (props) => {
  const { error, closeModal } = props

  const [fadeAway, setFadeAway] = useState('')

  let data = () => {
    if (error) {
      let mysolar = error.map((element) => {
        return Object.keys(element).map((element2) => {
          return (
            <p className="modal_text" key={element}>
              <span className="modal_title">{element2.toUpperCase()[0] + element2.slice(1)}:</span> {element[element2]}
            </p>
          )
        })
      })
      return mysolar
    }
  }

  const fader = () => {
    setFadeAway('slideAway')

    setTimeout(() => {
      closeModal()
    }, 800)
  }

  return (
    <div className={`modal ${fadeAway}`}>
      <div className={`modal_container $`}>
        <div className="modal_top">
          <div className="modal_circle modal_circle_red"></div>
          <div className="modal_circle modal_circle_yellow"></div>
          <div className="modal_circle modal_circle_green"></div>
        </div>
        <div className="modal_close" onClick={fader}>
          {vanilla.a4Close()}
        </div>
        <div className="modal_body">
          {data()}
          {/* <p className="modal_text">
            <span className="modal_title">Name:</span> Name is required fsd fsd fsdf fdsf sdf
          </p>
          <p className="modal_text">
            <span className="modal_title">Email:</span> Please enter a valid email
          </p>
          <p className="modal_text">
            <span className="modal_title">Message:</span> Message is required
          </p> */}
        </div>
      </div>
    </div>
  )
}

export default Modal
