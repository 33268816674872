import React from 'react'

import programmer from '../../media/images/programmer.jpg'
import Stacked from '../stacked/Stacked'
import Mouse from '../../components/mouse/mouse'
import bubble from '../../media/images/bubble.png'

const showcase = (props) => {
  const { executeScrollToTargetKnowMore, executeScrollToTargetContact } = props

  return (
    <div className="showcase_container">
      <div className="showcase">
        <div className="showcase_box showcase_box1">
          <img className="showcase_box1_image" src={programmer} alt="" />
          <p className="showcase_box1_paragraph mt-2rem">I'm a freelance full-stack web developer.</p>
          <div className="mt-4rem">
            <a className="btn btn_orange mx-1rem" href="#dan" onClick={executeScrollToTargetContact}>
              Contact Me
            </a>
            <a className="btn btn_red mx-1rem" href="#dan" onClick={executeScrollToTargetKnowMore}>
              Know More
            </a>
          </div>
        </div>
        <div className="showcase_box showcase_box2">
          <Stacked />
        </div>
      </div>
      <Mouse />
      <div className="showcase_design"></div>
      <div className="showcase_bubbles">
        <img className={`showcase_bubble`} src={bubble} alt="" />
        <img className={`showcase_bubble`} src={bubble} alt="" />
        <img className={`showcase_bubble`} src={bubble} alt="" />
        <img className={`showcase_bubble`} src={bubble} alt="" />
        <img className={`showcase_bubble`} src={bubble} alt="" />
        <img className={`showcase_bubble`} src={bubble} alt="" />
        <img className={`showcase_bubble`} src={bubble} alt="" />
        <img className={`showcase_bubble`} src={bubble} alt="" />
        <img className={`showcase_bubble`} src={bubble} alt="" />
      </div>
    </div>
  )
}

export default showcase
