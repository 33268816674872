import React from 'react'

const contactitemtextarea = (props) => {
  const { labelText, inputValue, inputName, handleOnChange, formDisable } = props

  return (
    <div className="contact_container">
      <div className="contact_group contact_group_textarea_container">
        <textarea
          className="contact_group_input contact_group_textarea"
          id={inputName}
          placeholder=" "
          required
          value={inputValue}
          onChange={handleOnChange}
          name={inputName}
          disabled={formDisable}
        />
        <label className="contact_group_label" htmlFor={inputName}>
          {labelText + ':'}
        </label>
        <div className="contact_group_line_container"></div>
      </div>
    </div>
  )
}

export default contactitemtextarea
