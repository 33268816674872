import React from 'react'
import Vanilla from '../../media/vanilla'
import SvgSlider from '../svgSlider/SvgSlider'

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_container">
        <p className="footer_title_2">Copyright&copy; Danny Nadarajah 2020</p>
        <div className="footer_icon_container">
          {/* <div className="footer_icon fill_github">{Vanilla.a2Github()}</div> */}

          <a href="https://github.com/NyMSolareXe" target="_blank" rel="noopener noreferrer">
            <span className="footer_icon_wrapper footer_icon_wrapper_github">
              <span className="footer_icon ">
                <span className="fill_github">{Vanilla.a2Github()}</span>
              </span>
            </span>
          </a>

          <a href="https://www.linkedin.com/in/danny-nadarajah-4403b6163/" target="_blank" rel="noopener noreferrer">
            <span className="footer_icon_wrapper footer_icon_wrapper_linkedin">
              <span className="footer_icon ">
                <span className="fill_linkedin">{Vanilla.a3Linkedin()}</span>
              </span>
            </span>
          </a>
        </div>
      </div>
      <h4 className="footer_title_1">NyMSolareXe@gmail.com</h4>
      <SvgSlider />
    </div>
  )
}

export default Footer
