import React, { useState, useEffect } from 'react'

import stackedSVG from '../../media/stackedSVG'
import helper from '../../helpers/bounding'

const Icon = () => {
  const [solar, setSolar] = useState('')

  useEffect(() => {
    window.addEventListener('scroll', scrollEventer)
    // eslint-disable-next-line
  }, [])

  let scrollEventer = (event) => {
    const target = document.querySelector('#icon')
    // console.log(target.getBoundingClientRect())

    // const bounding = target.getBoundingClientRect()

    // if (bounding.top >= 0) {
    //   console.log('bounding top good')
    // }

    // if (bounding.left >= 0) {
    //   console.log('bounding left good')
    // }

    // if (bounding.right <= (window.innerWidth || document.documentElement.clientWidth)) {
    //   console.log('bounding right good')
    // }

    // if (bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
    //   console.log('bounding bottom good')
    // }

    // console.log('bounding.top', bounding.top)

    // console.log('bounding.bottom', bounding.bottom)
    // console.log('window.innerheight', window.innerHeight)

    if (target) {
      const checker = helper.isInViewport(target)
      if (checker) {
        window.removeEventListener('scroll', scrollEventer)
        setSolar('solbox')
      }
    }
  }

  return (
    <div id="icon">
      <div className="icon">
        <div className={`icon_container ${solar}`}>
          <div className="icon_item">{stackedSVG.a9HTML()}</div>
          <div className="icon_item">{stackedSVG.a3CSS()}</div>
          <div className="icon_item">{stackedSVG.a5Javascript()}</div>
          <div className="icon_item">{stackedSVG.a2React()}</div>
          <div className="icon_item">{stackedSVG.a8NodeJS()}</div>
          <div className="icon_item">{stackedSVG.a4Express()}</div>
          <div className="icon_item">{stackedSVG.a6MongoDB()}</div>
          <div className="icon_item">{stackedSVG.a1PHP()}</div>
          <div className="icon_item">{stackedSVG.a7MYSQL()}</div>
        </div>
      </div>
    </div>
  )
}

export default Icon
