import React, { useState } from 'react'
import ContactitemInput from './contactitemInput'
import ContactitemTextArea from './contactitemtextarea'
import axios from 'axios'

const Contact = (props) => {
  const { contactRef, handleModalData } = props
  const [button, setButton] = useState(['', ''])

  const [formDisable, setFormDisable] = useState(['', ''])

  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  })

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const { name, email, message } = form
    const body = JSON.stringify({ name, email, message })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    try {
      await axios.post('api/v1/mail', body, config)
      setForm({
        name: '',
        email: '',
        message: '',
      })

      setButton(['btn_disabled', true])
      setFormDisable(['disabled', true])

      let solarity = [{ Success: 'Your message has been sent' }]

      handleModalData(solarity)
    } catch (error) {
      const data = error.response.data

      if (error.response.status === 500) {
        let dan = [{ Error: 'Server Error' }]
        handleModalData(dan)
      } else {
        handleModalData(data)
      }
    }
  }

  return (
    <div className={`contact ${formDisable[0]}`} ref={contactRef}>
      <h4 className="contact_title">A mission, or just a question? Use the form or contact me directly on my email.</h4>
      <form action="">
        <div className="contact_flexbox">
          <ContactitemInput
            labelText="Name"
            dataType="text"
            handleOnChange={onChange}
            inputValue={form.name}
            inputName={'name'}
            formDisable={formDisable[1]}
          />
          <ContactitemInput
            labelText="Email"
            dataType="email"
            handleOnChange={onChange}
            inputValue={form.email}
            inputName={'email'}
            formDisable={formDisable[1]}
          />
        </div>
        <div className="contact_spacer">
          <ContactitemTextArea
            labelText="Message"
            inputValue={form.message}
            handleOnChange={onChange}
            inputName={'message'}
            formDisable={formDisable[1]}
          />
        </div>
        <div className="contact_button_flexbox mt-2rem">
          <button className={`btn btn_blue ${button[0]}`} onClick={onSubmit} disabled={button[1]}>
            Send
          </button>
        </div>
      </form>
    </div>
  )
}

export default Contact
