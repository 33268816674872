import React from 'react'

const Display2 = (props) => {
  const { workRef } = props
  return (
    <div className="display display2">
      <div className="display_container">
        <h4 className="display_title" ref={workRef}>
          &nbsp;My Work&nbsp;
        </h4>
      </div>
    </div>
  )
}

export default Display2
