import React, { useEffect, useState } from 'react'
import helper from '../../helpers/bounding'

const Skillbox = () => {
  const [solar, setSolar] = useState('')

  const [text, setText] = useState([...Array(9)])

  const [informationIndex, setInformationIndex] = useState(0)
  const informationArray = [
    'Skill@webDev',
    ':~$',
    'Creating your website or powerful web apps with React.',
    'Skill@restAPI',
    ':~$',
    'Creating a RESTful API, with an authentication system. Powered by NODE + EXPRESS + MongoDB or PhP + Laravel + MYSQL.',
    'Skill@frontend',
    ':~$',
    'Creating your unique style with SASS.',
  ]

  const [marginBox, setMarginBox] = useState(['', '', ''])
  const [whiteBox, setWhiteBox] = useState(['', 'display_none', 'display_none'])

  useEffect(() => {
    window.addEventListener('scroll', scrollEventer)
    // eslint-disable-next-line
  }, [])

  let scrollEventer = (event) => {
    const target = document.querySelector('#skillbox')
    if (target) {
      const checker = helper.isInViewport(target)
      if (checker) {
        window.removeEventListener('scroll', scrollEventer)
        setSolar('solbox')
        let myString = informationArray[informationIndex]
        solon(myString, myDuration)
      }
    }
  }

  let myDuration = 25

  useEffect(() => {
    if (solar === 'solbox' && informationIndex !== -2 && informationIndex !== informationArray.length) {
      if ((informationIndex + 1) % 3 === 0) {
        let myIndex = (informationIndex + 1) / 3 - 1
        setMarginBox(
          marginBox.map((element, index) => {
            if (index === myIndex) {
              return 'setwidthmargin'
            }
            return element
          })
        )
      }

      if (informationIndex % 3 === 0) {
        setWhiteBox(
          whiteBox.map((element, index) => {
            if (informationIndex / 3 === index) {
              return ''
            }

            return 'display_none'
          })
        )
      }

      let myString = informationArray[informationIndex]
      solon(myString, myDuration)
    } else if (informationIndex === informationArray.length) {
      // skillbox_blinker

      setWhiteBox(
        whiteBox.map((element, index) => {
          if (index === whiteBox.length - 1) {
            return 'skillbox_blinker'
          }
          return 'display_none'
        })
      )
    }
    // eslint-disable-next-line
  }, [informationIndex])

  const solon = (string, duration) => {
    let index = 0
    let paragraph = ''
    const timer = setInterval(() => {
      paragraph += string[index]

      setText(
        text.map((element, index) => {
          if (informationIndex === index) {
            return paragraph
          }
          return element
        })
      )

      index = index + 1
      if (index === string.length) {
        clearInterval(timer)
        setInformationIndex(informationIndex + 1)
      }
    }, duration)
  }

  let guardian = -1
  let invoker = () => {
    guardian = guardian + 1
    return guardian
  }

  // setwidthmargin
  let pain = () => {
    return [...Array(3)].map((element, index) => (
      <div className="skillbox_text_wrapper" key={index}>
        <p className={`skillbox_text skillbox_${index}`}>
          <span className={`skillbox_text_normal ${marginBox[index]}`}>
            <span className="skillbox_text_green">{text[invoker()]}</span>
            {text[invoker()]}
          </span>
          {text[invoker()]}&nbsp;
          <span className="skillbox_home">
            &nbsp;<span className={`skillbox_square ${whiteBox[index]}`}></span>
          </span>
        </p>
      </div>
    ))
  }

  return (
    <div className={solar} id="skillbox">
      <div className="skillbox">
        <div className="skillbox_top">
          <div className="skillbox_circle skillbox_circle_red"></div>
          <div className="skillbox_circle skillbox_circle_yellow"></div>
          <div className="skillbox_circle skillbox_circle_green"></div>
        </div>

        <div className="skillbox_body">
          {pain()}

          {/* {[...Array(3)].map((element, index) => (
            <div className="skillbox_text_wrapper">
              <p className={`skillbox_text skillbox_${index}`}>
                <span className="skillbox_text_normal">
                  <span className="skillbox_text_green">{text[0]}</span>
                  {text[1]}
                </span>
                {text[2]}&nbsp;&nbsp;
                <span className={`skillbox_square skillbox_square_${index}`}></span>
              </p>
            </div>
          ))}

          <div className="skillbox_text_wrapper">
            <p className="skillbox_text skillbox_1">
              <span className="skillbox_text_normal">
                <span className="skillbox_text_green">{text[0]}</span>
                {text[1]}
              </span>
              {text[2]}&nbsp;&nbsp;
              <span className="skillbox_square skillbox_square_1"></span>
            </p>
          </div>

          <div className="skillbox_text_wrapper">
            <p className="skillbox_text skillbox_2">
              <span className="skillbox_text_normal">
                <span className="skillbox_text_green">Skill@restAPI</span>:~$
              </span>
              Creating a RESTful API, with an authentication system. Powered by NODE, EXPRESS, MongoDB and GraphQL.&nbsp;&nbsp;
              <span className="skillbox_square skillbox_square_2"></span>
            </p>
          </div>

          <div className="skillbox_text_wrapper">
            <p className="skillbox_text skillbox_3">
              <span className="skillbox_text_normal">
                <span className="skillbox_text_green">Skill@gameDev</span>:~$
              </span>
              Creating HTML5 browser games with the PHASER engine.&nbsp;&nbsp;
              <span className="skillbox_square skillbox_square_3"></span>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Skillbox
