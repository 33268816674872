import React from 'react'

import stackedSVG from '../../media/stackedSVG'

const Stacked = () => {
  return (
    <div className="stacked">
      <div className="stacked_items_wrapper">
        <div className="stacked_item_container3 item_slotA1">
          <div className="item_slotB1">
            <div className="stacked_item_container5 item_slotC1">{stackedSVG.a9HTML()}</div>
          </div>
        </div>
        <div className="stacked_item_container3 item_slotA2">
          <div className="item_slotB2">
            <div className="stacked_item_container5 item_slotC2">{stackedSVG.a3CSS()}</div>
          </div>
        </div>
        <div className="stacked_item_container3 item_slotA3">
          <div className="item_slotB3">
            <div className="stacked_item_container5 item_slotC3">{stackedSVG.a5Javascript()}</div>
          </div>
        </div>

        <div className="stacked_item_container3 item_slotA4">
          <div className="item_slotB4">
            <div className="stacked_item_container5 item_slotC4">{stackedSVG.a2React()}</div>
          </div>
        </div>

        <div className="stacked_item_container3 item_slotA5">
          <div className="item_slotB5">
            <div className="stacked_item_container5 item_slotC5">{stackedSVG.a8NodeJS()}</div>
          </div>
        </div>

        <div className="stacked_item_container3 item_slotA6">
          <div className="item_slotB6">
            <div className="stacked_item_container5 item_slotC6">{stackedSVG.a6MongoDB()}</div>
          </div>
        </div>

        <div className="stacked_item_container3 item_slotA7">
          <div className="item_slotB7">
            <div className="stacked_item_container5 item_slotC7">{stackedSVG.a4Express()}</div>
          </div>
        </div>

        <div className="stacked_item_container3 item_slotA8">
          <div className="item_slotB8">
            <div className="stacked_item_container5 item_slotC8">{stackedSVG.a1PHP()}</div>
          </div>
        </div>

        <div className="stacked_item_container3 item_slotA9">
          <div className="item_slotB9">
            <div className="stacked_item_container5 item_slotC9">{stackedSVG.a7MYSQL()}</div>
          </div>
        </div>

        {/* <div className="stacked_item_wrapper">
          <div className="stacked_item_container">{stackedSVG.a3CSS()}</div>
        </div>
        <div className="stacked_item_wrapper">
          <div className="stacked_item_container">{stackedSVG.a4Express()}</div>
        </div>

        <div className="stacked_item_wrapper">
          <div className="stacked_item_container">{stackedSVG.a5Javascript()}</div>
        </div>
        <div className="stacked_item_wrapper">
          <div className="stacked_item_container">{stackedSVG.a6MongoDB()}</div>
        </div>
        <div className="stacked_item_wrapper">
          <div className="stacked_item_container">{stackedSVG.a7MYSQL()}</div>
        </div>
        <div className="stacked_item_wrapper">
          <div className="stacked_item_container">{stackedSVG.a8NodeJS()}</div>
        </div>
        <div className="stacked_item_wrapper">
          <div className="stacked_item_container">{stackedSVG.a9HTML()}</div>
        </div> */}
      </div>
    </div>
  )
}

export default Stacked
