import React, { useRef, useState } from 'react'
import './solar.scss'

import Navbar from './components/navbar/navbar'
import Showcase from './components/showcase/showcase'
import Skillbox from './components/skillBox/skillbox'
import Icon from './components/icon/icon'
import Portfolio from './components/portfolio/portfolio'
import Contact from './components/contact/contact'
import Display1 from './components/display/display1'
import Display2 from './components/display/display2'
import Display3 from './components/display/display3'
import Footer from './components/footer/Footer'
import Modal from './components/modal/modal'

import rune10 from './media/images/bgs/rune10.jpg'
import loading from './media/images/loading8new.gif'

const App = () => {
  const navRef = useRef()
  const contactRef = useRef()
  const workRef = useRef()
  const knowMoreRef = useRef()

  const executeScrollToTarget = (e, refer, offset = 120) => {
    e.preventDefault()
    window.scrollTo({
      top: refer.current.offsetTop - offset,
      behavior: 'smooth',
    })
  }

  const [modal, SetModal] = useState(false)
  const [modalData, setModalData] = useState('')

  const [loadContent, setLoadContent] = useState(null)

  const handleModalData = (data) => {
    setModalData(data)
    SetModal(true)
  }

  const closeModal = () => {
    setModalData('')
    SetModal(false)
  }

  const imageLoaded = () => {
    // setTimeout(() => {
    setLoadContent('hello')
    // }, 2000)
  }

  if (loadContent === null) {
    return (
      <div className="loading">
        <div>
          <img src={loading} alt="" />
          <h1>Loading...</h1>
          <img onLoad={imageLoaded} src={rune10} style={{ display: 'none' }} />
        </div>
      </div>
    )
  }

  return (
    <div className="App">
      <Navbar
        executeScrollToTargetContact={(e) => executeScrollToTarget(e, contactRef)}
        executeScrollToTargetWork={(e) => executeScrollToTarget(e, workRef)}
        executeScrollToTargetNav={(e) => executeScrollToTarget(e, navRef)}
        navRef={navRef}
        knowMoreRef={knowMoreRef}
      />
      <Showcase
        executeScrollToTargetKnowMore={(e) => executeScrollToTarget(e, knowMoreRef, 105)}
        executeScrollToTargetContact={(e) => executeScrollToTarget(e, contactRef)}
      />
      <Display1 knowMoreRef={knowMoreRef} />

      <Skillbox />
      <Icon />
      <Display2 workRef={workRef} />

      <Portfolio />

      <Display3 />
      <Contact contactRef={contactRef} handleModalData={handleModalData} />
      <Footer />

      {modal && <Modal error={modalData} closeModal={closeModal} />}

      {/* <div className="stage_container">
        <div className="stage_1"></div>
        <div className="stage_2"></div>
        <div className="stage_3"></div>
        <div className="stage_4"></div>
      </div> */}
    </div>
  )
}

export default App
