const isInViewport = function (element) {
  let bounding = element.getBoundingClientRect()
  let height = window.innerHeight || document.documentElement.clientHeight
  let width = window.innerWidth || document.documentElement.clientWidth

  if (window.innerHeight < 600) {
    height = height + 300
  }

  if (window.innerWidth < 800) {
    width = width + 300
  }

  return bounding.top >= 0 && bounding.left >= 0 && bounding.right <= width && bounding.bottom <= height
}

const bounding = {
  isInViewport,
}

export default bounding
