import React, { useState, useEffect } from 'react'
// import helper from '../../helpers/bounding'

import solar3 from '../../media/images/solar3.png'

const Navbar = (props) => {
  const { executeScrollToTargetContact, executeScrollToTargetWork, executeScrollToTargetNav, navRef, knowMoreRef } = props

  const [solar, setSolar] = useState('')

  useEffect(() => {
    window.addEventListener('scroll', scrollEventer)
    // eslint-disable-next-line
  }, [])

  let scrollEventer = (event) => {
    const target = knowMoreRef.current
    if (target) {
      // const checker = helper.isInViewport(target)

      var bounding = target.getBoundingClientRect()

      if (bounding.top <= 106) {
        // window.removeEventListener('scroll', scrollEventer)
        setSolar('solbox')
      } else {
        setSolar('')
      }

      // return (
      //   bounding.top >= 0 &&
      //   bounding.left >= 0 &&
      //   bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
      //   bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      // )

      // const checker = false
      // if (checker) {
      //   window.removeEventListener('scroll', scrollEventer)
      //   setSolar('solbox')
      //   console.log('bingo')
      // }
    }
  }

  return (
    <div className={`navbar_wrapper ${solar}`}>
      <div className="navbar" ref={navRef}>
        {/* <h4 className="navbar_logo">Danny Nadarajah</h4> */}
        <div>
          <img src={solar3} alt="" className="navbar_logo" onClick={executeScrollToTargetNav} />
        </div>
        {/* <h4 className="navbar_logo">NyM Solar eXe</h4> */}
        <div className="navbar_links">
          <a className="navbar_link" href="#dan" onClick={executeScrollToTargetWork}>
            My Work
          </a>
          <a className="navbar_link" href="#dan" onClick={executeScrollToTargetContact}>
            Contact
          </a>
        </div>
      </div>
    </div>
  )
}

export default Navbar
